import { getCurrentLanguage } from '@/lib/languages';

const urls = {
  register_your_turbo: {
    'nl': 'https://turbopartner.th-group.eu/registreer-2/?cty=nl',
    'nl-be': 'https://turbopartner.th-group.eu/nl-be/registreer-3/?cty=nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/fr-be/enregistrez-3/?cty=fr-be',
    'fr': 'https://turbopartner.th-group.eu/fr/enregistrez-2/?cty=fr',
  },
  photo_video_diagnosis: {
    'nl': 'https://turbodiagnose.th-group.eu/app?cty=nl',
    'nl-be': 'https://turbodiagnose.th-group.eu/app?cty=nl-be',
    'fr-be': 'https://turbodiagnose.th-group.eu/app?cty=fr-be',
    'fr': 'https://turbodiagnose.th-group.eu/app?cty=fr',
  },
  knowledge_base: {
    'nl': 'https://turbodiagnose.th-group.eu/',
    'nl-be': 'https://turbodiagnose.th-group.eu/nl-be/',
    'fr-be': 'https://turbodiagnose.th-group.eu/fr-be/',
    'fr': 'https://turbodiagnose.th-group.eu/fr/',
  },
  submit_claim: {
    'nl': 'https://turbopartner.th-group.eu/garantieclaim/',
    'nl-be': 'https://turbopartner.th-group.eu/nl-be/garantieclaim2/',
    'fr-be': 'https://turbopartner.th-group.eu/fr-be/demande-de-garantie-2/',
    'fr': 'https://turbopartner.th-group.eu/fr/demande-de-garantie/',
  },
  webshop: {
    'nl': 'https://turboshop.th-group.eu/',
    'nl-be': 'https://turboshop.th-group.eu/nl-be/',
    'fr-be': 'https://turboshop.th-group.eu/fr-be/',
    'fr': 'https://turboshop.th-group.eu/fr/',
  },
  turbonews: {
    'nl': 'https://turbonews.th-group.eu/',
    'nl-be': 'https://turbonews.th-group.eu/nl-be/',
    'fr-be': 'https://turbonews.th-group.eu/fr-be/',
    'fr': 'https://turbonews.th-group.eu/fr/',
  },
};

type PageType = keyof typeof urls;

export function getPageURL(pageType: PageType) {
  // get language from local storage, fallback to nl
  const language = getCurrentLanguage();

  const pageInfo = urls[pageType];
  if (!pageInfo) {
    throw new Error(`Page "${pageType}" not found`);
  }

  // get url from urls object
  const url = urls[pageType][language];
  if (!url) {
    throw new Error(
      `Page "${pageType}" not found for language "${language}". Is it set in src/lib/pages.ts?`
    );
  }

  return urls[pageType][language];
}

