// Lit libraries
import { LitElement, css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import { translate as t } from 'lit-i18n';

import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import { styles } from '@/styles/shared-styles';

import { router } from '@/router';

import { appSettings } from '@/lib/settings';
import '@/components/button';

@customElement('app-home')
export class AppHome extends LitElement {
  @query('#calldialog') dialog!: HTMLDialogElement;

  render() {
    return html`
      <dialog id="calldialog" class="calldialog">
        <div class="calldialog__title">
          ${t('call_your_turbospecialist_dialog')}:
        </div>
        <div class="calldialog__buttons">
          <app-button
            icon="call-white"
            iconRight="flag-be"
            title="${t('belgium')}"
            subtitle="${appSettings.contact.tel.belgium.title}"
            href="tel:${appSettings.contact.tel.belgium.number}">
          </app-button>
          <app-button
            icon="call-white"
            iconRight="flag-fr"
            title="${t('france')}"
            subtitle="${appSettings.contact.tel.france.title}"
            href="tel:${appSettings.contact.tel.france.number}">
          </app-button>
          <app-button
            icon="call-white"
            iconRight="flag-nl"
            title="${t('netherlands')}"
            subtitle="${appSettings.contact.tel.netherlands.title}"
            href="tel:${appSettings.contact.tel.netherlands.number}"
            whatsapp="${appSettings.contact.whatsapp.netherlands.number}">
          </app-button>
        </div>
        <div class="calldialog__close" @click="${this.closeDialog}">
          <img src="/app/assets/images/close.svg" alt="" />
          <span>${t('close_window')}</span>
        </div>
      </dialog>
      <app-header></app-header>
      <main>
        <div class="text">
          <h1>${t('call_your_turbospecialist')}:</h1>
          <a href="#" @click="${this.openDialog}">
            <img
              class="callimage"
              src="/app/assets/images/call.svg"
              alt="${t('call_your_turbospecialist')}" />
          </a>
        </div>
        <div class="bottomcontainer">
          <app-button
            title="${t('register_your_turbo')}"
            icon="wrench"
            href="app/iframe/register_your_turbo">
          </app-button>
          <app-button
            title="${t('photo_video_diagnosis')}"
            icon="camera"
            href="app/iframe/photo_video_diagnosis">
          </app-button>
          <div class="bottomcontainer__smallbuttons">
            <app-button
              title="${t('knowledge_base')}"
              color="black"
              href="app/iframe/knowledge_base">
            </app-button>
            <app-button
              title="${t('submit_claim')}"
              color="black"
              href="app/iframe/submit_claim">
            </app-button>
            <app-button
              title="${t('webshop')}"
              color="black"
              href="app/iframe/webshop">
            </app-button>
            <app-button
              title="${t('turbonews')}"
              color="black"
              href="app/iframe/turbonews">
            </app-button>
          </div>
        </div>
      </main>
    `;
  }

  openDialog(event: Event) {
    if (event) {
      event.preventDefault();
    }
    this.dialog.showModal();
  }

  closeDialog(event: Event) {
    if (!event || !event.target) {
      return;
    }
    this.dialog.close();
  }

  openPage(evt: Event) {
    const target = evt.target as Element;
    if (target) {
      router.navigate(`app/iframe/${target.getAttribute('data-page')}`);
    }
  }

  static styles = [
    styles,
    css`
      main {
        height: 100vh;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        padding-top: var(--header-height);
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        text-transform: uppercase;
      }

      .text {
        background: radial-gradient(
            95.31% 95.31% at 50% 50%,
            #ffe763 25.4%,
            #ffd700 45.13%,
            #e3b511 100%
          ),
          var(--color-yellow);
        display: flex;
        padding-left: 16px;
        padding-right: 16px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        text-align: center;
      }

      /* smaller icon + text on smaller screens (either <=760 height or <=350 width) */
      @media (max-height: 760px), (max-width: 350px) {
        .text .callimage {
          height: 100px;
        }
        .text h1 {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .callimage {
        height: 169px;
        width: auto;
      }

      .bottomcontainer {
        background: #fff;
        border-radius: 35px 35px 0px 0px;
        box-shadow: 0px 15px 25px 10px rgba(227, 181, 17, 0.3),
          0px 5px 5px 0px rgba(227, 181, 17, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 24px;
        margin-top: -15px;
        width: 100%;
        background-color: #fff;
        border-radius: 15px 15px 0 0;
      }

      .bottomcontainer__smallbuttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        flex-flow: row wrap;
        gap: 1rem;
      }

      .bottomcontainer__smallbuttons app-button {
        width: calc(50% - 0.5rem);
        white-space: nowrap;
      }

      @media screen and (max-width: 350px) {
        .bottomcontainer__smallbuttons app-button {
          width: 100%;
        }
      }

      app-button {
        width: 100%;
      }

      .calldialog {
        width: 90vw;
        border-radius: 35px;
        background: #fff;
        padding: 24px;
        max-height: 90vh;
        border: 0;
        box-shadow: none;
      }

      .calldialog:focus-visible {
        outline: none;
      }

      .calldialog__title {
        color: var(--color-black);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      .calldialog__close {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        color: #000;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
      }

      .calldialog__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    `,
  ];
}

