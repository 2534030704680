// Lit libraries
import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

// Shoelace styling
import '@shoelace-style/shoelace/dist/themes/light.css';

// global styling
import '@/styles/global.css';

// own libs
import { router } from '@/router';

// own components
import '@/components/header';

@customElement('app-index')
export class AppIndex extends LitElement {
  firstUpdated() {
    router.addEventListener('route-changed', () => {
      if ('startViewTransition' in document) {
        (document as any).startViewTransition(() => this.requestUpdate());
      } else {
        this.requestUpdate();
      }
    });
  }

  render() {
    // router config can be round in src/router.ts
    return router.render();
  }
}

